<!-- Right side -->
<div>
  <div class="claim-filter-section">
    <div ngbAccordion>
      <div
        ngbAccordionItem="claimfilter"
        #claimfilter="ngbAccordionItem"
        [collapsed]="false"
        class="padding-15"
      >
        <div ngbAccordionHeader>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="accordion-header">
              {{
                "InvoiceClaimPage.InvoiceClaimApproval.InvoiceClaimsApprovalHeader"
                  | translate
              }}
            </h5>
            <button ngbAccordionToggle class="btn btn-link accordion">
              {{ !claimfilter.collapsed ? "-" : "+" }}
            </button>
          </div>
        </div>
        <div ngbAccordionCollapse>
          <div class="padding-left-0" ngbAccordionBody>
            <ng-template>
              <p class="claimDescription">
                {{
                  "InvoiceClaimPage.InvoiceClaimApproval.InvoiceClaimApprovalDescription"
                    | translate
                }}
              </p>
              <div
                class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100"
                [appIsLoading]="loadingService.isLoading(this)"
              >
                <div class="margin-bottom-10">
                  <div
                    class="grid grid-gap-10 grid-col-sm-100 grid-col-xs-100 grid-col-hd-50"
                  >
                    <div class="margin-bottom-10">
                      <label>
                        {{
                          "InvoiceClaimPage.InvoiceClaimApproval.SearchbySKUs"
                            | translate
                        }}</label
                      >
                      <br />
                      <input
                        #couponId
                        placeholder="SKU"
                        [(ngModel)]="filter.sku"
                        class="customField"
                      />
                    </div>
                    <div class="margin-bottom-10">
                      <label>
                        {{
                          "InvoiceClaimPage.InvoiceClaimApproval.InvoiceNumber"
                            | translate
                        }}</label
                      >
                      <br />
                      <input
                        #couponId
                        placeholder="Invoice Number"
                        [(ngModel)]="filter.invoiceNumber"
                        class="customField"
                      />
                    </div>
                  </div>
                </div>
                <div class="margin-bottom-10">
                  <div
                    class="grid grid-gap-10 grid-col-sm-100 grid-col-xs-100 grid-col-hd-50"
                  >
                    <div class="margin-bottom-10">
                      <label>{{
                        "InvoiceClaimPage.InvoiceClaimApproval.DateofSales"
                          | translate
                      }}</label>
                      <br />
                      <div class="form-group item">
                        <div class="input-group">
                          <label
                            for="fromdate"
                            class="datePlacement lbl-date"
                            >{{
                              "InvoiceClaimPage.InvoiceClaimApproval.From"
                                | translate
                            }}</label
                          >
                          <input
                            class="form-control datePickerCalander"
                            placeholder="mm-dd-yyyy"
                            name="fromSellingDate"
                            ngbDatepicker
                            #dpsellingFromDate="ngbDatepicker"
                            [maxDate]="maxSaleFromDate"
                            [minDate]="minSaleFromDate"
                            readonly
                            [(ngModel)]="filter.fromSellingDate"
                            (ngModelChange)="blockOldDateSales('from')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              type="button"
                              (click)="dpsellingFromDate.toggle()"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="margin-bottom-10">
                      <label></label>
                      <br />
                      <div class="form-group item">
                        <div class="input-group">
                          <label for="toDate" class="datePlacement lbl-date">{{
                            "InvoiceClaimPage.InvoiceClaimApproval.To"
                              | translate
                          }}</label>
                          <input
                            class="form-control datePickerCalander"
                            placeholder="mm-dd-yyyy"
                            name="toSellingDate"
                            ngbDatepicker
                            #dpsellingToDate="ngbDatepicker"
                            readonly
                            [maxDate]="maxSaleToDate"
                            [minDate]="minSaleToDate"
                            [(ngModel)]="filter.toSellingDate"
                            (ngModelChange)="blockOldDateSales('to')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              type="button"
                              (click)="dpsellingToDate.toggle()"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <div
                    class="grid grid-gap-10 grid-col-sm-100 grid-col-xs-100 grid-col-hd-50"
                  >
                    <div class="margin-bottom-10">
                      <label>{{
                        "InvoiceClaimPage.InvoiceClaimApproval.Organisation"
                          | translate
                      }}</label>
                      <br />
                      <input
                        #Organisation
                        placeholder="Organisation"
                        [(ngModel)]="filter.Organisation"
                        class="customField"
                      />
                    </div>
                    <div class="margin-bottom-10">
                      <label>{{
                        "InvoiceClaimPage.InvoiceClaimApproval.SellingUser"
                          | translate
                      }}</label>
                      <br />
                      <input
                        #sellingUser
                        placeholder="Selling User"
                        [(ngModel)]="filter.sellingUser"
                        class="customField"
                      />
                    </div>
                  </div>
                </div>
                <div class="margin-bottom-10">
                  <div
                    class="grid grid-gap-10 grid-col-sm-100 grid-col-xs-100 grid-col-hd-50"
                  >
                    <div class="margin-bottom-10">
                      <label>{{
                        "InvoiceClaimPage.InvoiceClaimApproval.DateofClaim"
                          | translate
                      }}</label>
                      <br />
                      <div class="form-group item">
                        <div class="input-group">
                          <label
                            for="fromdate"
                            class="datePlacement lbl-date"
                            >{{
                              "InvoiceClaimPage.InvoiceClaimApproval.From"
                                | translate
                            }}</label
                          >
                          <input
                            class="form-control datePickerCalander"
                            placeholder="mm-dd-yyyy"
                            name="claimFromDate"
                            ngbDatepicker
                            #dpclaimFromDate="ngbDatepicker"
                            readonly
                            [maxDate]="maxClaimFromDate"
                            [minDate]="minClaimFromDate"
                            [(ngModel)]="filter.fromClaimDate"
                            (ngModelChange)="blockOldDateClaim('from')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              type="button"
                              (click)="dpclaimFromDate.toggle()"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="margin-bottom-10">
                      <label></label>
                      <br />
                      <div class="form-group item">
                        <div class="input-group">
                          <label
                            for="fromdate"
                            class="datePlacement lbl-date"
                            >{{
                              "InvoiceClaimPage.InvoiceClaimApproval.To"
                                | translate
                            }}</label
                          >
                          <input
                            class="form-control datePickerCalander"
                            placeholder="mm-dd-yyyy"
                            name="toClaimDate"
                            ngbDatepicker
                            #dpclaimToDate="ngbDatepicker"
                            readonly
                            [maxDate]="maxClaimToDate"
                            [minDate]="minClaimToDate"
                            [(ngModel)]="filter.toClaimDate"
                            (ngModelChange)="blockOldDateClaim('to')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              type="button"
                              (click)="dpclaimToDate.toggle()"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <label>{{
                    "InvoiceClaimPage.InvoiceClaimApproval.SearchbyStatus"
                      | translate
                  }}</label>
                  <br />
                  <ng-multiselect-dropdown
                    name="list"
                    [placeholder]="'Search By Status'"
                    [settings]="invoiceStatusSettings"
                    [data]="invoiceStatus"
                    [(ngModel)]="filter.selectedItems"
                  >
                  </ng-multiselect-dropdown>
                </div>
                <div class="margin-bottom-10"></div>
              </div>

              <div class="row">
                <div class="col-md-12 text-center btn-align">
                  <button
                    class="btn btn-primary customBtnSearch"
                    (click)="onSearch()"
                  >
                    Search
                  </button>
                  <button
                    class="btn btn-primary customBtnReset"
                    (click)="onClear()"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="content-width">
      <h1 class="sub-pageHeader">
        {{
          "InvoiceClaimPage.InvoiceClaimApproval.InvoiceClaimBulkApproval"
            | translate
        }}
      </h1>
    </div>
    <div class="claim-filter-section">
      <div
        class="upload-textwrap"
        [innerHTML]="
          'InvoiceClaimPage.InvoiceClaimApproval.Upload.UploadColumns'
            | translate
        "
      ></div>
      <br />
      <div class="addContactControls col-100">
        <cs-file-uploader-without-mapping
          *ngIf="contextId"
          [lastUploadId]="lastUploadId"
          uploadType="BulkInvoiceClaim"
          [contextId]="contextId"
          [importBehaviors]="importBehaviors"
          [header]="
            'InvoiceClaimPage.InvoiceClaimApproval.Upload.UploadHeader'
              | translate
          "
          showPicker="true"
          uploadButtonEnabled="true"
          [userId]="currentUser.Id"
          (onImportComplete)="importCompleted($event)"
        >
        </cs-file-uploader-without-mapping>
      </div>
    </div>

    <div class="content-width">
      <h1 class="sub-pageHeader">
        {{
          "InvoiceClaimPage.InvoiceClaimApproval.InvoiceClaimsApprovalHeader"
            | translate
        }}
      </h1>
    </div>

    <div>
      <div class="claim-filter-section">
        <div class="row" *ngIf="paginationDetails.totalNumberOfItems > 0">
          <div class="col-12 col-md-6">
            <div>
              <p>
                {{ "TableRecords.FoundTotalOf" | translate
                }}{{ paginationDetails.totalNumberOfItems
                }}{{ "TableRecords.matches" | translate }}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="grid grid-col-xs-100 grid-col-hd-50 grid-gap-10">
              <cs-items-per-page
                class=""
                [paginationDetails]="paginationDetails"
                (onChangeEvent)="onChange($event)"
              ></cs-items-per-page>
              <div class="pull-right">
                <div class="downloadText" (click)="downloadCSV()">
                  Download Report <span class="downloadIcons"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="table-width"
          #dataLoading="appIsLoading"
          [appIsLoading]="loadingService.isLoading(this)"
        >
          <table
            class="table table-striped"
            aria-describedby="myTable"
            *ngIf="paginationDetails.totalNumberOfItems !== 0"
          >
            <thead>
              <tr>
                <th
                  class="cm_12 cm_bold"
                  role="button"
                  scope="col"
                  (click)="onSort('userName')"
                >
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.SellingUser"
                      | translate
                  }}
                  <cs-sort-icons
                    *ngIf="sorter.showSortIcons('userName')"
                    [sortAscending]="sorter.sortDirection"
                  ></cs-sort-icons>
                </th>
                <th
                  class="cm_12 cm_bold"
                  role="button"
                  (click)="onSort('invoiceNumber')"
                  scope="col"
                >
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.InvoiceNo"
                      | translate
                  }}

                  <cs-sort-icons
                    *ngIf="sorter.showSortIcons('invoiceNumber')"
                    [sortAscending]="sorter.sortDirection"
                  ></cs-sort-icons>
                </th>
                <th
                  class="cm_12 cm_bold"
                  role="button"
                  (click)="onSort('dateOfSale')"
                  scope="col"
                >
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.DateofSale"
                      | translate
                  }}

                  <cs-sort-icons
                    *ngIf="sorter.showSortIcons('dateOfSale')"
                    [sortAscending]="sorter.sortDirection"
                  ></cs-sort-icons>
                </th>
                <th
                  class="cm_12 cm_bold"
                  role="button"
                  (click)="onSort('sku')"
                  scope="col"
                >
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.SKU"
                      | translate
                  }}

                  <cs-sort-icons
                    *ngIf="sorter.showSortIcons('sku')"
                    [sortAscending]="sorter.sortDirection"
                  ></cs-sort-icons>
                </th>
                <th
                  class="cm_12 cm_bold"
                  role="button"
                  (click)="onSort('quantity')"
                  scope="col"
                >
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.Qty"
                      | translate
                  }}
                  <cs-sort-icons
                    *ngIf="sorter.showSortIcons('quantity')"
                    [sortAscending]="sorter.sortDirection"
                  ></cs-sort-icons>
                </th>
                <th
                  class="cm_12 cm_bold"
                  role="button"
                  (click)="onSort('value')"
                  scope="col"
                >
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.Value"
                      | translate
                  }}
                  <cs-sort-icons
                    *ngIf="sorter.showSortIcons('value')"
                    [sortAscending]="sorter.sortDirection"
                  ></cs-sort-icons>
                </th>
                <th
                  class="cm_12 cm_bold"
                  role="button"
                  (click)="onSort('organisation')"
                  scope="col"
                >
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.Organisation"
                      | translate
                  }}
                  <cs-sort-icons
                    *ngIf="sorter.showSortIcons('organisation')"
                    [sortAscending]="sorter.sortDirection"
                  ></cs-sort-icons>
                </th>
                <th
                  class="cm_12 cm_bold"
                  role="button"
                  (click)="onSort('claimedDate')"
                  scope="col"
                >
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.ClaimDate"
                      | translate
                  }}
                  <cs-sort-icons
                    *ngIf="sorter.showSortIcons('claimedDate')"
                    [sortAscending]="sorter.sortDirection"
                  ></cs-sort-icons>
                </th>
                <th
                  class="cm_12 cm_bold"
                  *ngFor="let attribute of additionalAttributes"
                >
                  {{ attribute }}
                </th>
                <th
                  class="cm_12 cm_bold"
                  role="button"
                  (click)="onSort('status')"
                  scope="col"
                >
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.Status"
                      | translate
                  }}
                  <cs-sort-icons
                    *ngIf="sorter.showSortIcons('status')"
                    [sortAscending]="sorter.sortDirection"
                  ></cs-sort-icons>
                </th>
                <th scope="col">
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.RejectReason"
                      | translate
                  }}
                </th>
                <th class="cm_12 cm_bold" role="button" scope="col">
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.Action"
                      | translate
                  }}
                </th>
                <th class="cm_12 cm_bold" role="button" scope="col">
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.Download"
                      | translate
                  }}
                </th>
                <th scope="col">
                  {{
                    "InvoiceClaimPage.InvoiceClaimApproval.ApprovalTable.Remarks"
                      | translate
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let invoice of invoicesForApproval">
                <td>{{ invoice.userName }}</td>
                <td>{{ invoice.invoiceNumber }}</td>
                <td>{{ invoice.dateOfSale | localizedDate }}</td>
                <td>{{ invoice.sku }}</td>
                <td>{{ invoice.quantity }}</td>
                <td>{{ invoice.value }}</td>
                <td>{{ invoice.organisation }}</td>
                <td>{{ invoice.claimedDate | localizedDate }}</td>
                <td *ngFor="let attribute of additionalAttributes">
                  {{ invoice.attributesDto[attribute] }}
                </td>
                <td>{{ invoice.status }}</td>
                <td>
                  <span *ngIf="invoice.status != 'Approved'">{{
                    invoice.rejectReason
                  }}</span>
                </td>
                <td>
                  <div
                    *ngIf="
                      !(isButtonDisable && invoice.lineItemId == lineItemId)
                    "
                  >
                    <span
                      class="success"
                      (click)="approve(invoice)"
                      *ngIf="!invoice.disableApprove"
                    ></span>
                    <span
                      class="reject"
                      (click)="reject(invoice)"
                      *ngIf="!invoice.disableReject"
                    ></span>
                  </div>
                </td>
                <td>
                  <span
                    class="download"
                    (click)="downloadInvoiceFilesModal(invoice)"
                  ></span>
                </td>
                <td>{{ invoice.remarks }}</td>
              </tr>
            </tbody>
          </table>
          <div
            class="invoiceApprovalNoData alert alert-danger"
            *ngIf="
              !dataLoading.appIsLoading &&
              paginationDetails.totalNumberOfItems === 0
            "
          >
            {{ "InvoiceClaimPage.NoRecordsFound" | translate }}
          </div>
        </div>
        <div class="row" *ngIf="paginationDetails.totalNumberOfItems > 0">
          <div class="col-lg-12 pagination-center-Bottom">
            <cs-custom-pagination
              [paginationDetails]="paginationDetails"
              (onChangeEvent)="onChange($event)"
            >
            </cs-custom-pagination>
          </div>
        </div>
        <div
          *ngIf="duplicateError"
          class="mainPointSummaryNoData alert alert-danger text-center"
        >
          Duplicate entries<br />
          <div [innerHtml]="duplicateError"></div>
        </div>
      </div>
    </div>
  </div>
</div>
