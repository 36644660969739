import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { HttpService } from '@core/shared';

import {
  GET_ORDER_HISTORY_DETAILS,
  RESEND_VOUCHER,
  UrlBuilder
} from '@core/core-api-variables';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

@Injectable()
export class OrderManagementService {

  constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) { }

  getOrderHistoryDetails(queryString): Observable<any> {
    let url = UrlBuilder(GET_ORDER_HISTORY_DETAILS, queryString);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  sendEGV(payload): Observable<any> {
    return this.http.post(RESEND_VOUCHER, payload).pipe(map((response: any) => {
      return response;
    }));
  }
}
