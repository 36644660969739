
import { Routes } from '@angular/router';
import { PreloginRoutes } from '@core/prelogin/prelogin.routes';

import {
    SalesClaimRoutes, PointSummaryRoutes, CouponClaimRoutes, InvoiceClaimRoutes, LegalPageRoutes, QuizRoutes, DashboardRoutes,
    GoalQuestRoutes, GameRoutes, MyPerformanceRoutes, MyAwardsRoutes, EmbeddedReportRoutes, MessagePageRoutes, QuestionPageRoutes,
    ErrorRoutes, ContentPageRoutes, LeagueRoutes, HubPageRoutes, AccountRoutes, TabViewRoutes, CommunicationRoutes, RegistrationApprovalRoutes, ContactUsPageRoutes,
} from '@core/private';
import { MembershipTieringRoutes } from './private/membership-tiering/membership-tiering-routes';
import { MyThresholdAwardsRoutes } from './private/mythresholdawards/my-threshold-awards.routes';
import { NotificationCornerRoutes } from './private/notificationcorner';
import { CatalogueAccessRoutes } from './private/catalogueaccess/catalogueaccess.routes';
import { CashRewardsRoute } from './private/cashrewards/cashrewards.routes';
import { OrderManagementRoutes } from './private/ordermanagement';

export const CoreRoutes: Routes = [
    ...DashboardRoutes,
    ...PreloginRoutes,
    ...SalesClaimRoutes,
    ...PointSummaryRoutes,
    ...OrderManagementRoutes,
    ...CouponClaimRoutes,
    ...InvoiceClaimRoutes,
    ...LegalPageRoutes,
    ...QuizRoutes,
    ...GoalQuestRoutes,
    ...GameRoutes,
    ...MyPerformanceRoutes,
    ...MyAwardsRoutes,
    ...EmbeddedReportRoutes,
    ...MessagePageRoutes,
    ...QuestionPageRoutes,
    ...ErrorRoutes,
    ...ContentPageRoutes,
    ...LeagueRoutes,
    ...HubPageRoutes,
    ...AccountRoutes,
    ...MyThresholdAwardsRoutes,
    ...MembershipTieringRoutes,
    ...NotificationCornerRoutes,
    ...TabViewRoutes,
    ...CommunicationRoutes,
    ...RegistrationApprovalRoutes,
    ...ContactUsPageRoutes,
    ...CatalogueAccessRoutes,
    ...CashRewardsRoute,
    {
        path: '**',
        redirectTo: ''
    }
];