<div class="home-page">
    <div class="bg-color3 home-page-bg">
        <div class="content-width home-point-gallery-section" #dataLoading="appIsLoading"
            [appIsLoading]="loadingService.isLoading(this)">
            <div class="flex-1 home-page-slide rightPane p-4">
                <p class="page-header mb-4" *ngIf="!isWebInMobileView">
                    {{'OrderHistory.Title' | translate}}</p>
                <div class="bg-color2 p-4">
                    <div ngbAccordion animation="true" closeOthers="true">
                        <div ngbAccordionItem [collapsed]="i != 0 ? true : false"
                            *ngFor="let item of tableDisplayData; let i = index">
                            <h2 ngbAccordionHeader>
                                <button ngbAccordionButton class="accordion-button d-flex">
                                    <p class="product-name mb-0">{{'OrderHistory.Title' | translate}}
                                        {{item?.orderNumber}} - <span class="font-light">{{item?.productName}}
                                            ({{item?.orderStatus}})</span></p>
                                </button>
                            </h2>
                            <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                    <ng-template>
                                        <div class="mb-4 container-item p-1">
                                            <div class="parent-div justify-content-between d-flex">
                                                <div class="column-left">
                                                    <p class="brand-name">{{'OrderHistory.Brand' | translate}}
                                                        {{item?.brand}}</p>
                                                    <p class="gift-code">{{'OrderHistory.GiftCode' | translate}}
                                                        {{item?.giftCode}}</p>
                                                </div>
                                                <div class="column-right img-product">
                                                    <img src="{{item?.imageUrl}}" class="product-image"
                                                        alt="No Image!" />
                                                </div>
                                            </div>
                                            <div class="parent-div justify-content-between d-flex pt-3">
                                                <div class="column-left">
                                                    {{'OrderHistory.OrderedOn' | translate}}
                                                </div>
                                                <div class="column-right">
                                                    {{item?.orderedOn}}
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="parent-div justify-content-between d-flex"
                                                *ngIf="item?.dispatchDate">
                                                <div class="column-left">
                                                    {{'OrderHistory.ShippedDate' | translate}}
                                                </div>
                                                <div class="column-right">
                                                    {{item?.dispatchDate}}
                                                </div>
                                            </div>
                                            <hr *ngIf="item?.dispatchDate">
                                            <div class="parent-div justify-content-between d-flex"
                                                *ngIf="item?.deliveryDate">
                                                <div class="column-left">
                                                    {{ item?.statusFlow?.indexOf('Delivered') != -1 ?
                                                    ('OrderHistory.DeliveredDate' | translate) :
                                                    ('OrderHistory.DeliveryDate' | translate)}}
                                                </div>
                                                <div class="column-right">
                                                    {{item?.deliveryDate}}
                                                </div>
                                            </div>
                                            <hr *ngIf="item?.deliveryDate">
                                            <div class="parent-div justify-content-between d-flex"
                                                *ngIf="item?.cancellationDate">
                                                <div class="column-left">
                                                    {{'OrderHistory.CancelledDate' | translate}}
                                                </div>
                                                <div class="column-right">
                                                    {{item?.cancellationDate}}
                                                </div>
                                            </div>
                                            <hr *ngIf="item?.cancellationDate">
                                            <div class="parent-div justify-content-between d-flex">
                                                <div class="column-left">
                                                    {{'OrderHistory.UnitPoints' | translate}}
                                                </div>
                                                <div class="column-right">
                                                    {{item?.unitPoints}}
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="parent-div justify-content-between d-flex">
                                                <div class="column-left">
                                                    {{'OrderHistory.TotalPoints' | translate}}
                                                </div>
                                                <div class="column-right">
                                                    {{item?.totalPoints}}
                                                </div>
                                            </div>
                                            <hr *ngIf="item?.courierName">
                                            <div class="parent-div justify-content-between d-flex"
                                                *ngIf="item?.courierName">
                                                <div class="column-left">
                                                    {{'OrderHistory.CourierName' | translate}}
                                                </div>
                                                <div class="column-right">
                                                    {{item?.courierName}}
                                                </div>
                                            </div>
                                            <hr *ngIf="item?.awbNo">
                                            <div class="parent-div justify-content-between d-flex" *ngIf="item?.awbNo">
                                                <div class="column-left">
                                                    {{'OrderHistory.AWB' | translate}}
                                                </div>
                                                <div class="column-right">
                                                    {{item?.awbNo}}
                                                </div>
                                            </div>
                                            <div *ngIf="(item?.orderType.toLowerCase() == 'egv' && item?.orderStatus.toLowerCase() == 'delivered')"
                                                class="pt-4 text-center">
                                                <button class="btn btn-primary customBtnSearch"
                                                    (click)="resendEGV(item?.biUniqueId)" type="button">
                                                    {{'OrderHistory.BtnEGVResend' | translate}}</button>
                                            </div>
                                            <div class="px-5">
                                                <cs-goal-progress-bar-chart class="block margin-top-20"
                                                    [progressPercentage]="item?.progress" trackColor="#f0f0f0"
                                                    progressedTrackColor="linear-gradient(to right, #53C56D, #53C56D)"
                                                    progressIcon="/assets/images/target_goal_rank_car_icon.png"
                                                    [hideTopPoints]="true" goalIcon="/assets/images/profile_target.png"
                                                    [loadOnInit]="true" orderManagementView="true"
                                                    [targets]="item?.targets" [noZeroStarting]="false">
                                                </cs-goal-progress-bar-chart>
                                            </div>
                                            <div class="py-5">
                                                <div class="order-status-section"
                                                    [ngClass]="{'status-cancelled': item?.orderStatus.toLowerCase() == 'cancelled'}">
                                                    <p class="mb-0 text-center py-3">{{item?.orderStatus}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <p class="mb-0 p-4" *ngIf="tableDisplayData?.length == 0">{{'OrderHistory.NoRecordsFound' |
                            translate}}</p>
                    </div>

                    <div class="pt-4" *ngIf="tableDisplayData && tableDisplayData.length > 0">
                        <div class="col-lg-12 pagination-center-Bottom">
                            <cs-custom-pagination [paginationDetails]="paginationDetails"
                                (onChangeEvent)="onChange($event)">
                            </cs-custom-pagination>
                        </div>
                    </div>
                </div>

            </div>
            <div class="home-left leftPane" *ngIf="!isWebInMobileView">
                <cs-profile-point-card theme="theme-white" buttonTheme="theme2"></cs-profile-point-card>
                <cs-most-redeem-products [singleView]="true"></cs-most-redeem-products>
            </div>
        </div>
    </div>
</div>