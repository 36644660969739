import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { StartupModule } from '@core/startup/startup.module';
import { SharedModule } from '@core/shared/shared.module';
import { CustomAdapter, CustomDateParserFormatter } from './shared';
import { PreloginModule } from '@core/prelogin';
import {
    HomeModule, LegalPageModule, PointsummaryModule, SalesClaimModule, CouponclaimModule, InvoiceclaimModule, QuizQuestionnaireModule, GameModule,
    GoalQuestModule, MyPerformanceModule, MyAwardsModule, EmbeddedReportModule, MessagePageModule, QuestionPageModule, ContentPageModule, LeagueModule,
    HubPageModule, AccountsModule, TabViewModule, CommunicationModule, RegistrationApprovalModule
} from '@app/core/private';
import { MyThresholdAwardsModule } from './private/mythresholdawards/my-threshold-awards.module';
import { MembershipTieringModule } from './private/membership-tiering/membership-tiering-modules';
import { NotificationCornerModule } from './private/notificationcorner';
import { CatalogueAccessModule } from './private/catalogueaccess';
import { CashrewardsModule } from './private/cashrewards/cashrewards.module';
import { OrderManagementModule } from './private/ordermanagement';


@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        TranslateModule,
        ToastrModule.forRoot({ maxOpened: 1, preventDuplicates: true }),
        StartupModule,
        SharedModule,
        HomeModule,
        LegalPageModule,
        PreloginModule,
        PointsummaryModule,
        OrderManagementModule,
        CouponclaimModule,
        InvoiceclaimModule,
        SalesClaimModule,
        GoalQuestModule,
        QuizQuestionnaireModule,
        GameModule,
        MyPerformanceModule,
        MyAwardsModule,
        EmbeddedReportModule,
        MessagePageModule,
        QuestionPageModule,
        ContentPageModule,
        LeagueModule,
        HubPageModule,
        AccountsModule,
        MyThresholdAwardsModule,
        MembershipTieringModule,
        NotificationCornerModule,
        TabViewModule,
        CommunicationModule,
        RegistrationApprovalModule,
        CatalogueAccessModule,
        CashrewardsModule
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})
export class AppCoreModule { }