import { Routes } from '@angular/router';
import { AuthGuard, RouteGuard } from '@core/shared';
import { OrderHistoryComponent } from './orderhistory/orderhistory.component';

export const OrderManagementRoutes: Routes = [
    {
        path: 'orderhistory',
        canActivate: [AuthGuard, RouteGuard],
        component: OrderHistoryComponent,
        pathMatch: 'full',
        data: { title: 'Order tracking', isPreLogin: false }
    }
];