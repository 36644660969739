import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'order-history-modal',
    templateUrl: './orderhistorymodal.component.html'
})
export class OrderHistoryModalComponent implements OnInit {

    @Input() message: string = '';

    constructor(private activeModal: NgbActiveModal) { }

    ngOnInit() {
    }
    cancel() {
        this.activeModal.dismiss();
    }
}
