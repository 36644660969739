import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Pagination } from '@core/shared/models/pagination';
import { LoadingService } from '@core/shared';
import { OrderManagementService } from '../service/ordermanagement.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderHistoryModalComponent } from '../orderhistorymodal/orderhistorymodal.component';

@UntilDestroy()
@Component({
    selector: 'app-order-tracking',
    templateUrl: 'orderhistory.component.html',
    styleUrls: [`orderhistory.component.scss`]
})

export class OrderHistoryComponent implements OnInit {
    tableDisplayData: any = [];
    paginationDetails: Pagination = new Pagination();
    public isWebInMobileView: boolean = false;
    constructor(public loadingService: LoadingService, private orderManagementService: OrderManagementService, private modalService: NgbModal) {
        this.isWebInMobileView = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token')).hideHeaderFooter ? true : false;
    }

    onChange(pagination: Pagination) {
        this.paginationDetails = pagination;
        this.getOrderHistory();
    }

    ngOnInit() {
        this.getOrderHistory();
    }

    resendEGV(id) {
        this.loadingService.doLoading(this.orderManagementService.sendEGV({ BiUniqueId: id }), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                const modalRef = this.modalService.open(OrderHistoryModalComponent, { backdrop: 'static' });
                modalRef.componentInstance.message = response?.message ? response?.message : "The Voucher resend is failed!";
            });
    }

    getOrderHistory() {
        this.loadingService.doLoading(this.orderManagementService.getOrderHistoryDetails({ perPage: 10, page: this.paginationDetails?.pageNumber ? this.paginationDetails?.pageNumber : 1 }), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.tableDisplayData = [];
                this.paginationDetails.totalNumberOfItems = response?.totalPages ? response?.totalPages * 10 : 0;
                response?.orders?.forEach(async (x) => {
                    const averageWidth = x.statusFlow?.length < 2 ? 100 : Math.ceil(100 / (x.statusFlow?.length - 1));
                    let className = '';
                    const targets = x.statusFlow?.map((y, index) => {
                        let width = (index || x.statusFlow?.length > 1) ? averageWidth * index : averageWidth;
                        if (index && x.statusFlow?.length > 3 && index < 3) {
                            width = width - 2;
                        }
                        const obj = { "bgTransparentDot": false, "width": width > 100 ? '100%' : width + '%', "name": y, "className": className };
                        if (y == x.orderStatus) {
                            className = 'progress-dot-gray';
                        }
                        return obj;
                    });
                    x.progress = targets?.find(y => y.name.toLowerCase() == x.orderStatus.toLowerCase())?.width?.replace("%", "");
                    x.targets = targets;
                    this.tableDisplayData.push(x);
                });
            });
    }
}